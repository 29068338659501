import React from "react";
import PageHeader from "../../components/common/PageHeader";
import useInventory from "./useInventory";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";

function Inventory() {
  const {
    allInventory,
    table_columns,
    paginationData,
    loading,
    helperFunctionSales,
  } = useInventory();

  const handleNextPage = (page) => {
    page--;
    if (page < paginationData.totalPages && loading === -1) {
      helperFunctionSales(page);
    }
  };
  return (
    <div className="container-xxl">
      <PageHeader headerTitle="Inventory" />
      <div className="container mt-4">
        <DataTable
          columns={table_columns}
          data={allInventory}
          pagination
          paginationServer
          paginationTotalRows={paginationData.totalElements}
          style={{
            tableLayout: {
              backgroundColor: "red",
            },
          }}
          paginationDefaultPage={paginationData.page + 1}
          onChangePage={handleNextPage}
          highlightOnHover
          responsive
          progressPending={loading === 1}
          progressComponent={
            <div className="text-center py-3">
              <Spinner animation="border" size="lg" role="status" />
              <p>Loading orders...</p>
            </div>
          }
          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
          customStyles={{
            headCells: {
              style: {
                backgroundColor: "#f1f5f9",
                fontWeight: "bold",
              },
            },
            rows: {
              style: {
                minHeight: "32px",
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default Inventory;

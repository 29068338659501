import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"; // Update the import statement
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/scss/main.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
const rootElement = document.getElementById("root");

// Use createRoot from "react-dom/client" instead of ReactDOM.createRoot
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

import axios from "axios";
import { API_URL } from "../../constants/api-url";

const baseUrlOrder = `${API_URL.backend_url}order-service/`;

export const createOrderApi = async (payload, token) => {
  const url = baseUrlOrder + "orders";
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "post",
    headers: header,
    url: url,
    data: payload,
  });
  return resp.data;
};

export const createBulkOrderApi = async (salesType, payload, token) => {
  const url = baseUrlOrder + "createOrderInBulk?salesType=" + salesType;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "post",
    headers: header,
    url: url,
    data: payload,
  });
  return resp.data;
};
export const updateOrderApi = async (id, status,remarks, token) => {
  const url = baseUrlOrder + `orders/${id}`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "put",
    headers: header,
    url: url,
    data: {
      status,
      remarks
    },
  });
  return resp.data;
};
export const updateOrderBulkApi = async (token, data) => {
  const url = baseUrlOrder + `updateOrderInBulk`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "put",
    headers: header,
    url: url,
    data: data,
  });
  return resp.data;
};

export const getAllOrderByFMCGClientAndSales = async (
  page,
  size,
  id,
  salesLevel,
  token
) => {
  const url =
    baseUrlOrder +
    `getAllOrderByClientFmcgIdAndSalesLevel?clientFmcgId=${id}&salesLevel=${salesLevel}&page=${page}&size=${size}`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "get",
    headers: header,
    url: url,
  });
  return resp.data;
};

export const getAllOrderByReportingManager = async (
  page,
  size,
  id,
  salesLevel,
  token
) => {
  const url =
    baseUrlOrder +
    `getAllOrderByReportingManagerMembers/${id}?salesLevel=${salesLevel}&page=${page}&size=${size}&sortBy=createdDate&sortDirection=desc`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "get",
    headers: header,
    url: url,
  });
  return resp.data;
};

export const getAllOrderByInvoiceNumber = async (invoiceNumber, token) => {
  const url = baseUrlOrder + `getAllOrderByInvoiceNumber/${invoiceNumber}`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "get",
    headers: header,
    url: url,
  });
  return resp.data;
};

export const getOrderByOrderId = async (orderId, token, salesLevel) => {
  const url =
    baseUrlOrder + `getOrderDetailBySalesLevelById/${orderId}/${salesLevel}`;
  var header = {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };
  const resp = await axios(url, {
    method: "get",
    headers: header,
    url: url,
  });
  return resp.data;
};
